/* eslint-disable jsx-a11y/anchor-is-valid */

import { BiBuildingHouse } from "react-icons/bi";
import { FaInstagram, FaLinkedin, FaTwitter,FaInstagramSquare, FaYoutube } from "react-icons/fa";
import { FiFacebook } from "react-icons/fi";
import { RiInstagramFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import logo from "../../images/Maya-Logopack12.png"
import mayadan from "../../images/mayadan.png"
import emlakjet from "../../images/emlakjet_white.png"
import mg from "../../images/mg_white.png"




const Footer = () => {
  return (
    <div className="px-[2%] md:px-[6%] bg-card-dark border border-card-dark">
    <div className="mt-10">
    <div className="text-slate-200">
      <footer>
        <div className="flex flex-wrap gap-2">
          <div className="flex-1 basis-[10rem]">
          <div className="flex justify-center items-center h-[100px] sm:h-[180px]">
           <img src={logo} alt="" className="w-[270px] h-auto" />
         </div>

            <div className="mt-3">
              
              <div className="gap-5 my-6 flex-center-center">
                <a href="https://www.facebook.com/mayagayrimenkulbandirma">
                <div className="icon-box  bg-[#EFBB4C]  hover:bg-hover-color-dark">
                  <FiFacebook  className="text-[#252836]  w-[25px] h-auto"/>
                </div></a>

                <a href="https://twitter.com/mayagayrimenkuI">
                <div className="icon-box  bg-[#EFBB4C]  hover:bg-hover-color-dark">
                  <FaTwitter  className="text-[#252836]  w-[25px] h-auto"/>
                </div></a>

                <a href="https://instagram.com/mayagayrimenkulkirklareli/"><div className="icon-box  bg-[#EFBB4C] hover:bg-hover-color-dark">
                  <FaInstagram   className="text-[#252836]  w-[25px] h-auto"/> 
                </div></a>

                <a href="https://www.linkedin.com/company/maya-gayrimenkul-ve-yat%C4%B1r%C4%B1m-dan%C4%B1%C5%9Fmanl%C4%B1%C4%9F%C4%B1/">
                <div className="icon-box bg-[#EFBB4C]  hover:bg-hover-color-dark">
                  <FaLinkedin  className="text-[#252836] w-[25px] h-auto"/>
                </div>
                </a>
              </div>
            </div>
          </div>

        

          <div className="flex-1 basis-[10rem] text-center">
            <h2 className="text-xl font-semibold">Hızlı Bağlantı</h2>
            <ul>
            <li className="my-3 text-muted">
                <a href="/">Anasayfa</a>
              </li>
              <li className="my-3 text-muted">
                <a href="/about"> Hakkımızda</a>
              </li>
              <li className="my-3 text-muted">
                <a href="/adverts">İlanlarımız</a>
              </li>
              
              <li className="my-3 text-muted">
                <a href="/contact">İletişim</a>
              </li>
            </ul>
          </div>

        

          <div className="flex-1 basis-[10rem] pt-10 sm:pt-0">
          <div className="flex justify-center items-center h-[100px] sm:h-[180px]">
           <img src={mayadan} alt="" className="w-[325px] h-auto" />
         </div>

            <div className="">
              
              <div className="gap-5 my-6 flex-center-center">
               

                <a href="https://www.instagram.com/mayadantv/"><div className="icon-box bg-[#EFBB4C] hover:bg-hover-color-dark">
                  <FaInstagram  className=" text-[#252836] w-[25px] h-auto"/>
                </div></a>
                
                <a href="https://youtube.com/@Mayadantv">
                <div className="icon-box bg-[#EFBB4C]  hover:bg-hover-color-dark">
                  <FaYoutube className="text-[#252836] w-[25px] h-auto" />
                </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    <div className="py-2 mt-3 text-center border-t text-muted border-dark col">
     <div className="flex justify-between">
       <a href="https://www.emlakjet.com/emlak-ofisleri-detay/maya-gayrimenkul-yatirim-1389280/"><img src={emlakjet} alt="" className="w-[120px] sm:w-[120px] h-auto" /> </a>
       <p className="text-xs sm:text-base">
         Mayadan Gayrimenkul & Yatırım Danışmanlığı | Emlakjet Entegrasyonu | Tüm Hakları Saklıdır © 2023
       </p>
       <a href="https://mgreklamajans.com.tr/"><img src={mg} alt="" className="w-[110px] sm:w-[120px] h-auto" /> </a>
     </div>
   </div>


    </div>
    </ div>

    </div>
  );
};

export default Footer;
